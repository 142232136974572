import { template as template_fce403f04b224a718badead2928cc02f } from "@ember/template-compiler";
const FKText = template_fce403f04b224a718badead2928cc02f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
