import { template as template_244db9c64fb64c4eb772b4a12575cd42 } from "@ember/template-compiler";
const SidebarSectionMessage = template_244db9c64fb64c4eb772b4a12575cd42(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
