import { template as template_0de2f6c6c1844f7aa83154271b8d27c2 } from "@ember/template-compiler";
const WelcomeHeader = template_0de2f6c6c1844f7aa83154271b8d27c2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
